import React from "react"
import Layout from "../components/layout"
import AboutMe from "../components/about-me"
import ThoughtsArchive from "../components/thoughts-archive"

const Index = () => (
  <Layout>
    <ThoughtsArchive />
    <AboutMe />
  </Layout>
)

export default Index
